import React, { lazy, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import ProtectedRoute from './components/Routes/ProtectedRoute'
import ForwardRoute from './components/Routes/ForwardRoute'

const Layout = lazy(() => import('./containers/Layout'))
const Auth = lazy(() => import('./containers/Auth'))
const UnverifiedEmail = lazy(() => import('./pages/UnverifiedEmail'))
const EmailVerification = lazy(() => import('./pages/EmailVerification'))

function App() {
  useEffect(() => {
    // Sendinblue tracking script
    const sibScript = document.createElement('script');
    sibScript.type = 'text/javascript';
    sibScript.async = true;
    sibScript.innerHTML = `
      (function() {
          window.sib = {
              equeue: [],
              client_key: "1mu4ff71i7d66ptttdubnmwo"
          };
          window.sendinblue = {};
          for (var j = ['track', 'identify', 'trackLink', 'page'], i = 0; i < j.length; i++) {
          (function(k) {
              window.sendinblue[k] = function() {
                  var arg = Array.prototype.slice.call(arguments);
                  (window.sib[k] || function() {
                          var t = {};
                          t[k] = arg;
                          window.sib.equeue.push(t);
                      })(arg[0], arg[1], arg[2], arg[3]);
                  };
              })(j[i]);
          }
          var n = document.createElement("script"),
              i = document.getElementsByTagName("script")[0];
          n.type = "text/javascript", n.id = "sendinblue-js", n.async = !0, n.src = "https://sibautomation.com/sa.js?key=" + window.sib.client_key, i.parentNode.insertBefore(n, i), window.sendinblue.page();
      })();
    `;
    document.body.appendChild(sibScript);

    // Razorpay checkout script
    const razorpayScript = document.createElement('script');
    razorpayScript.src = 'https://checkout.razorpay.com/v1/checkout.js';
    razorpayScript.async = true;
    document.body.appendChild(razorpayScript);

    return () => {
      document.body.removeChild(sibScript);
      document.body.removeChild(razorpayScript);
    };
  }, []);

  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <ForwardRoute path="/auth" component={Auth} />
          <ProtectedRoute path="/app" component={Layout} />
          <Route exact path="/unverified-email" component={UnverifiedEmail} />
          <Route exact path="/verify-email" component={EmailVerification} />
          <Redirect from="*" to="/app" />
        </Switch>
      </Router>
    </>
  )
}

export default App;
